import Hero from "../components/Hero/Hero";
import Vehicle from "../components/Vehicle/Vehicle";
import Pricing from "../components/Pricing/Pricing";
import Bio from "../components/Bio/Bio";

const HomePage = () => {
  return (
    <>
      <Hero />
      <Vehicle />
      <Pricing />
      <Bio />
    </>
  );
};

export default HomePage;
