import classes from "./Gallery.module.css";
import Gallery1 from "../../assets/gallery/gallery1.jpg";
import Gallery2 from "../../assets/gallery/gallery2.jpg";
import Gallery3 from "../../assets/gallery/gallery3.jpg";
import Gallery4 from "../../assets/gallery/gallery4.jpg";
import Gallery5 from "../../assets/gallery/gallery5.jpg";
import Gallery6 from "../../assets/gallery/gallery6.jpg";
import Gallery7 from "../../assets/gallery/gallery7.jpg";
import Gallery8 from "../../assets/gallery/gallery8.jpg";
import Gallery9 from "../../assets/gallery/gallery9.jpg";
import Gallery10 from "../../assets/gallery/gallery10.jpg";
import Gallery11 from "../../assets/gallery/gallery11.jpg";
import Gallery12 from "../../assets/gallery/gallery12.jpg";

const Gallery = () => {
  return (
    <section>
      <div className="container">
        <span className="subHeading center">Memories</span>
        <h2 className="headingSecondary center">Album</h2>
      </div>
      <div className={`container ${classes.galleryContainer}`}>
        <div className={classes.imageContainer}>
          <img
            className={classes.galleryImage}
            src={Gallery1}
            alt="Saradhi album"
          />
        </div>
        <div className={classes.imageContainer}>
          <img
            className={classes.galleryImage}
            src={Gallery2}
            alt="Saradhi album"
          />
        </div>
        <div className={classes.imageContainer}>
          <img
            className={classes.galleryImage}
            src={Gallery3}
            alt="Saradhi album"
          />
        </div>
        <div className={classes.imageContainer}>
          <img
            className={classes.galleryImage}
            src={Gallery4}
            alt="Saradhi album"
          />
        </div>
        <div className={classes.imageContainer}>
          <img
            className={classes.galleryImage}
            src={Gallery5}
            alt="Saradhi album"
          />
        </div>
        <div className={classes.imageContainer}>
          <img
            className={classes.galleryImage}
            src={Gallery6}
            alt="Saradhi album"
          />
        </div>
        <div className={classes.imageContainer}>
          <img
            className={classes.galleryImage}
            src={Gallery7}
            alt="Saradhi album"
          />
        </div>
        <div className={classes.imageContainer}>
          <img
            className={classes.galleryImage}
            src={Gallery8}
            alt="Saradhi album"
          />
        </div>
        <div className={classes.imageContainer}>
          <img
            className={classes.galleryImage}
            src={Gallery9}
            alt="Saradhi album"
          />
        </div>
        <div className={classes.imageContainer}>
          <img
            className={classes.galleryImage}
            src={Gallery10}
            alt="Saradhi album"
          />
        </div>
        <div className={classes.imageContainer}>
          <img
            className={classes.galleryImage}
            src={Gallery11}
            alt="Saradhi album"
          />
        </div>
        <div className={classes.imageContainer}>
          <img
            className={classes.galleryImage}
            src={Gallery12}
            alt="Saradhi album"
          />
        </div>
      </div>
    </section>
  );
};

export default Gallery;
