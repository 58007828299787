import classes from "./Vehicle.module.css";
import Nissan from "../../assets/nissan.png";
import Splender from "../../assets/splender.png";

const Vehicle = () => {
  return (
    <section className={classes.sectionVehicle}>
      <div className={classes.carSection}>
        <div className={`container ${classes.vehicle} ${classes.vehicleCar}`}>
          {/*----------Vehicle lmv-------------*/}
          <div className={classes.vehicleTextBox}>
            <h2 className="headingTertiary">
              Looking for a Car driving licence?
            </h2>
            <p className={classes.vehiclePara}>
              We will provide the best training experience with highly skilled
              professional trainers. We are equipped with compact SUVs and
              Hatchbacks to train you on all road conditions including gradient
              track.
            </p>
            <a href="#price" className="btn btnRed">
              Start Training
            </a>
          </div>
          <div
            className={`${classes.vehicleImgBox} ${classes.vehicleImgBoxCar}`}
          >
            <img
              className={classes.vehicleImg}
              src={Nissan}
              alt="Car used for training"
            />
          </div>
        </div>
      </div>
      <div className={classes.bikeSection}>
        <div className={`container ${classes.vehicle} ${classes.vehicleBike}`}>
          {/*----------course bike-------------*/}
          <div
            className={`${classes.vehicleImgBox} ${classes.vehicleImgBoxBike}`}
          >
            <img
              className={classes.vehicleImg}
              src={Splender}
              alt="Bike used for training"
            />
          </div>
          <div className={classes.vehicleTextBox}>
            <h2 className="headingTertiary">
              Looking for a Bike driving licence?
            </h2>
            <p className={classes.vehiclePara}>
              We will provide the best training experience with highly skilled
              professional trainers. We are equipped with geared and gearless
              motorbikes to train you on all road conditions.
            </p>
            <a href="#price" className="btn btnViolet">
              Start Training
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Vehicle;
