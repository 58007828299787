import classes from "./Bio.module.css";

const Bio = () => {
  return (
    <section className={classes.sectionBio}>
      <div className={classes.BioText}>
        <h2 className={`headingSecondary ${classes.BioHeading}`}>Since 1994</h2>
        <p className={classes.BioTextPara}>
          <span>&quot;</span>
          Saradhi driving training center was established in the year 1994 with
          an aim of training students to drive safely in Indian road conditions.
          In our journey, we always provided the best for our students. We have
          the best training grounds, vehicles, and trainers to make our students
          pro drivers. Now we are one of the best driving schools in India
          providing all standards of training to students.
          <span>&quot;</span>
        </p>
      </div>
    </section>
  );
};

export default Bio;
