import Gallery from "../components/Gallery/Gallery";

const GalleryPage = () => {
  return (
    <>
      <Gallery />
    </>
  );
};

export default GalleryPage;
