import classes from "./About.module.css";
import AboutImage from "../../assets/about_image.jpg";

const About = () => {
  return (
    <section>
      <div className="container">
        <span className="subHeading center">Get to Know</span>
        <h2 className="headingSecondary center">About Us</h2>
      </div>
      <div className={`container ${classes.aboutContainer}`}>
        <div className={classes.aboutTextBox}>
          <p className={classes.aboutPara}>
            We started our training center in the year 1994 with an aim of
            training students to drive safely in Indian road conditions. We
            follow a progressive curriculum to make our students drive safely.
            We have fully equipped training grounds, vehicles, and experienced
            professional trainers to make our students pro drivers.
          </p>
          <p className={classes.aboutPara}>
            With uncompromising quality standards, we are always the best place
            to start your driving.
          </p>
        </div>
        <div className={classes.aboutImageBox}>
          <img
            className={classes.aboutImg}
            src={AboutImage}
            alt="Saradhi Surendran"
          />
        </div>
      </div>
    </section>
  );
};

export default About;
