import { Link } from "react-router-dom";

import classes from "./Pricing.module.css";

import { AiOutlineCheck } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";

const Pricing = () => {
  return (
    <section className={classes.sectionPricing} id="price">
      <div className="container">
        <span className="subHeading center">Training Programmes</span>
        <h2 className="headingSecondary center">Courses</h2>
      </div>

      <div className={`container ${classes.plans}`}>
        {/*----------plan1-------------*/}
        <div className={classes.plan}>
          <header className={classes.planHeader}>
            <p className={classes.planName}>LMV</p>
            <p className={classes.planPrice}>
              <span>&#8377;</span>10,000
            </p>
            <p className={classes.planText}>Light Motor Vehicle</p>
          </header>
          <ul className={`list ${classes.adList}`}>
            <li className={`listItem ${classes.adListItem}`}>
              <AiOutlineCheck className={classes.adListIcon} />
              <p>22 Training classes</p>
            </li>
            <li className={`listItem ${classes.adListItem}`}>
              <AiOutlineCheck className={classes.adListIcon} />
              <p>Ground training</p>
            </li>
            <li className={`listItem ${classes.adListItem}`}>
              <AiOutlineCheck className={classes.adListIcon} />
              <p>Road training</p>
            </li>
            <li className={`listItem ${classes.adListItem}`}>
              <AiOutlineCheck className={classes.adListIcon} />
              <p>Gradient training</p>
            </li>
            <li className={`listItem ${classes.adListItem}`}>
              <AiOutlineClose className={classes.adListIcon} />
            </li>
          </ul>
          <div className={classes.planSignup}>
            <Link className="btn btnGrey" to="/contactus">
              Start Training
            </Link>
          </div>
        </div>

        {/*----------plan2-------------*/}
        <div className={`${classes.plan} ${classes.planComplete}`}>
          <header className={classes.planHeader}>
            <p className={classes.planName}>LMV + M/CY</p>
            <p className={classes.planPrice}>
              <span>&#8377;</span>16,000
            </p>
            <p className={classes.planText}>Light Motor Vehicle &</p>
            <p className={classes.planText}>Motorcycle</p>
          </header>
          <ul className={`list ${classes.adList}`}>
            <li className={`listItem ${classes.adListItem}`}>
              <AiOutlineCheck className={classes.adListIcon} />
              <p>22 LMV training classes</p>
            </li>
            <li className={`listItem ${classes.adListItem}`}>
              <AiOutlineCheck className={classes.adListIcon} />
              <p>20 M/CY training classes</p>
            </li>
            <li className={`listItem ${classes.adListItem}`}>
              <AiOutlineCheck className={classes.adListIcon} />
              <p>Ground training</p>
            </li>
            <li className={`listItem ${classes.adListItem}`}>
              <AiOutlineCheck className={classes.adListIcon} />
              <p>Road training</p>
            </li>
            <li className={`listItem ${classes.adListItem}`}>
              <AiOutlineCheck className={classes.adListIcon} />
              <p>Gradient training</p>
            </li>
          </ul>
          <div className={classes.planSignup}>
            <Link className="btn btnGrey" to="/contactus">
              Start Training
            </Link>
          </div>
        </div>

        {/*----------plan3-------------*/}
        <div className={classes.plan}>
          <header className={classes.planHeader}>
            <p className={classes.planName}>M/CY</p>
            <p className={classes.planPrice}>
              <span>&#8377;</span>7,000
            </p>
            <p className={classes.planText}>Motorcycle</p>
          </header>
          <ul className={`list ${classes.adList}`}>
            <li className={`listItem ${classes.adListItem}`}>
              <AiOutlineCheck className={classes.adListIcon} />
              <p>20 Training classes</p>
            </li>
            <li className={`listItem ${classes.adListItem}`}>
              <AiOutlineCheck className={classes.adListIcon} />
              <p>Ground training</p>
            </li>
            <li className={`listItem ${classes.adListItem}`}>
              <AiOutlineCheck className={classes.adListIcon} />
              <p>Road training</p>
            </li>
            <li className={`listItem ${classes.adListItem}`}>
              <AiOutlineCheck className={classes.adListIcon} />
              <p>Gradient training</p>
            </li>
            <li className={`listItem ${classes.adListItem}`}>
              <AiOutlineClose className={classes.adListIcon} />
            </li>
          </ul>
          <div className={classes.planSignup}>
            <Link className="btn btnGrey" to="/contactus">
              Start Training
            </Link>
          </div>
        </div>
        {/*----------end of plan3-------------*/}
      </div>
    </section>
  );
};

export default Pricing;
