import classes from "./Hero.module.css";
import Saradhi from "../../assets/saradhi.png";

const Hero = () => {
  return (
    <header className={classes.headerHero}>
      <div className={`container ${classes.hero}`}>
        <div className={classes.heroLeft}>
          <h1 className="headingPrimary">
            <span className={classes.headline}>
              Learn to <span className={classes.heroHighlight}>Drive</span>
            </span>
            <span className={classes.headline}>
              Follow the <span className={classes.heroHighlight}>Rules</span>
            </span>
          </h1>
          <p>
            We provide exceptional training programmes that make you confident
            to drive in any road conditions
          </p>
          <p>We are the best and we make you the best</p>
          <a href="#price" className="btn btnGrey">
            Start Driving
          </a>
        </div>
        <div className={classes.heroRight}>
          <img className={classes.heroimg} src={Saradhi} alt="Saradhi Logo" />
        </div>
      </div>
    </header>
  );
};

export default Hero;
