import classes from "./MainNav.module.css";
import Logo from "../../assets/saradhi_font.png";
import { Link, NavLink } from "react-router-dom";

const MainNav = () => {
  return (
    <>
      <div className={classes.mobNav}>
        <input
          type="checkbox"
          className={classes.mobNavCheckbox}
          id="naviToggle"
        />
        <label
          id="checkboxLabel"
          htmlFor="naviToggle"
          className={classes.mobNavButton}
        >
          <span className={classes.mobNavIcon}>&nbsp;</span>
        </label>
        <div className={classes.mobNavBackground}>&nbsp;</div>
        <nav className={classes.mobNavNavigation}>
          <ul className={classes.mobNavList}>
            <li className={classes.mobNavItem}>
              <Link
                onClick={() => {
                  document.getElementById("checkboxLabel").click();
                }}
                className={classes.mobNavLink}
                to="/home"
              >
                Home
              </Link>
            </li>
            <li className={classes.mobNavItem}>
              <Link
                onClick={() => {
                  document.getElementById("checkboxLabel").click();
                }}
                className={classes.mobNavLink}
                to="/about"
              >
                About
              </Link>
            </li>
            <li className={classes.mobNavItem}>
              <Link
                onClick={() => {
                  document.getElementById("checkboxLabel").click();
                }}
                className={classes.mobNavLink}
                to="/gallery"
              >
                Gallery
              </Link>
            </li>
            <li className={classes.mobNavItem}>
              <Link
                onClick={() => {
                  document.getElementById("checkboxLabel").click();
                }}
                className={classes.mobNavLink}
                to="/contactus"
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      <header className={classes.mainHeader}>
        <nav className={`container ${classes.navigation}`}>
          <div className={classes.logoBox}>
            <Link to="/home">
              <img className={classes.logo} src={Logo} alt="Saradhi Logo" />
            </Link>
          </div>
          <div className={classes.deskNavLinks}>
            <NavLink
              className={(navData) => (navData.isActive ? classes.active : "")}
              to="/home"
            >
              Home
            </NavLink>
            <NavLink
              className={(navData) => (navData.isActive ? classes.active : "")}
              to="/about"
            >
              About
            </NavLink>
            <NavLink
              className={(navData) => (navData.isActive ? classes.active : "")}
              to="/gallery"
            >
              Gallery
            </NavLink>
            <NavLink
              className={(navData) => (navData.isActive ? classes.active : "")}
              to="/contactus"
            >
              Contact Us
            </NavLink>
          </div>
        </nav>
      </header>
    </>
  );
};

export default MainNav;
