import classes from "./Footer.module.css";
import { Link } from "react-router-dom";

import { BsInstagram } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";

import FooterLogo from "../../assets/logo_footer.png";

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <div className={`container ${classes.footerGrid}`}>
        <div className={classes.Logocol}>
          <Link className={classes.LogoLink} to="/home">
            <img
              className={classes.footerLogo}
              src={FooterLogo}
              alt="Saradhi Logo"
            />
          </Link>

          <ul className={classes.socialLinks}>
            <li>
              <a
                className={classes.socialLink}
                href="https://www.instagram.com"
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram className={classes.socialIcons} />
              </a>
            </li>
            <li>
              <a
                className={classes.socialLink}
                href="https://www.facebook.com"
                target="_blank"
                rel="noreferrer"
              >
                <BsFacebook className={classes.socialIcons} />
              </a>
            </li>
            <li>
              <a
                className={classes.socialLink}
                href="https://twitter.com/"
                target="_blank"
                rel="noreferrer"
              >
                <BsTwitter className={classes.socialIcons} />
              </a>
            </li>
          </ul>
          <p className={classes.copyright}>
            Copyright &copy; 2022 by Saradhi, Inc. All rights reserved.
          </p>
        </div>

        <div className={classes.addressCol}>
          <p className={classes.addressHead}>Address</p>
          <p className={classes.address}>
            Madavi Complex, 2nd floor, Kannapuram, Kannur
          </p>
          <p className={classes.address}>
            Khairu Complex, 2nd floor, Irinav Road, Kannur
          </p>
        </div>

        <div className={classes.linksCol}>
          <Link className={classes.pageLink} to="/about">
            About
          </Link>
          <Link className={classes.pageLink} to="/gallery">
            Gallery
          </Link>
          <Link className={classes.pageLink} to="/contactus">
            Contact Us
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
