import MainNav from "../MainNav/MainNav";
import Footer from "../Footer/Footer";

const Layout = (props) => {
  return (
    <>
      <MainNav />
      <main>{props.children}</main>
      <Footer />
    </>
  );
};

export default Layout;
