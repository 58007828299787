import Layout from "./components/Layout/Layout";
import { Routes, Route, Navigate } from "react-router-dom";

import ScrollToTop from "./components/Layout/ScrollToTop";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import GalleryPage from "./pages/GalleryPage";
import ContactPage from "./pages/ContactPage";

const App = () => {
  return (
    <ScrollToTop>
      <Layout>
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/contactus" element={<ContactPage />} />
        </Routes>
      </Layout>
    </ScrollToTop>
  );
};

export default App;
