import { useRef } from "react";
import emailjs from "@emailjs/browser";
import classes from "./Contact.module.css";

import { MdOutlineMail } from "react-icons/md";
import { RiMessengerLine } from "react-icons/ri";
import { BsWhatsapp } from "react-icons/bs";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(
      "service_gic2vdh",
      "template_vw2gpyu",
      form.current,
      "UvftfSPfWyclGq7ax"
    );

    e.target.reset();
  };

  return (
    <section>
      <div className="container">
        <span className="subHeading center">Get in Touch</span>
        <h2 className="headingSecondary center">Message Us</h2>
      </div>
      <div className={`container ${classes.contactContainer}`}>
        <div className={classes.contactOptions}>
          <div className={classes.contactOption}>
            <a
              className={`${classes.contactOptionLink} ${classes.contactOptionMailLink}`}
              href="mailto:saradhidtc@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <MdOutlineMail className={classes.contactOptionIcon} />
              <h3 className={classes.contactOptionHead}>Email</h3>
              <p className={classes.contactOptionText}>saradhidtc@gmail.com</p>
            </a>
          </div>
          <div className={classes.contactOption}>
            <a
              className={`${classes.contactOptionLink} ${classes.contactOptionMessLink}`}
              href="https://m.me/saradhidtc"
              target="_blank"
              rel="noreferrer"
            >
              <RiMessengerLine className={classes.contactOptionIcon} />
              <h3 className={classes.contactOptionHead}>Messenger</h3>
              <p className={classes.contactOptionText}>saradhidtc</p>
            </a>
          </div>
          <div className={classes.contactOption}>
            <a
              className={`${classes.contactOptionLink} ${classes.contactOptionWhatLink}`}
              href="https://wa.me/+919995695792"
              target="_blank"
              rel="noreferrer"
            >
              <BsWhatsapp className={classes.contactOptionIcon} />
              <h3 className={classes.contactOptionHead}>Whatsapp</h3>
              <p className={classes.contactOptionText}>+91 9447 2288 56</p>
            </a>
          </div>
        </div>
        <div className={classes.formContainer}>
          <form className={classes.form} ref={form} onSubmit={sendEmail}>
            <input
              className={classes.formInput}
              type="text"
              name="name"
              placeholder="Your Fullname"
              required
            />
            <input
              className={classes.formInput}
              type="email"
              name="email"
              placeholder="Your Email"
              required
            />
            <input
              className={classes.formInput}
              type="text"
              name="number"
              placeholder="Your Mobile number"
              required
            />
            <textarea
              className={classes.formInput}
              name="message"
              rows="7"
              placeholder="Your Message"
              required
            />
            <button
              className={`btn btnGrey ${classes.formButton}`}
              type="submit"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
